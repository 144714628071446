import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const CustomerAppealSns = ({ location }) => {
  const title =
    "訴求効果ピカイチ!! 手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？ ～"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="フォロー＆リツイートキャンペーンとハッシュタグキャンペーンを徹底解説 | 手軽で効果的なSNS広告"
          pagedesc="手軽に・成果が見えて・訴求効果があるTwitter運用について解説します。販促効果を高めるために、特に人気なフォロー＆リツイートキャンペーンとハッシュタグキャンペーンのどちらを実施すべきかわかります。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/customer-appeal-SNS.jpg"
          pagepath="/blog/customer-appeal-SNS"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は3分で読むことができます
            </div>
            <h1>
              訴求効果ピカイチ!!
              手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
              ～
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年1月18日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="コールセンターの女性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/customer-appeal-SNS.jpg"
                className="w-full"
              />
            </figure>
            <p>近年のマーケティング担当者がPR活動で求めるものは３つ。</p>
            <p>
              <strong>
                それは「手軽で」「効果が見えて」「訴求効果がある」というもの。
              </strong>
            </p>
            <p>
              特にマーケティング部署の担当者や、広告代理店勤務であれば上司やクライアントに成果を報告する場合にも数値化が必要になってくるケースが増えていることでしょう。
            </p>
            <p>
              こうなると負のスパイラルで何もできず、そうこうしているうちにライバル企業にゴッソリ持っていかれ……なんてケースも。
            </p>
            <p>
              広告訴求効果を上司に説明できず、結局広告を一回も出せないという悩みを持つ方も多くいると耳にします。
            </p>
            <p>
              でも「手軽で」「効果が見えて」「訴求効果がある」なんて、そんな三拍子がそろった牛丼チェーンみたいなおいしい話があるんかいっ、とツッコみを入れたあなた。
            </p>
            <p>
              実は唯一無二のソリューションがあるんです。
              <strong>それが「SNS運用」。</strong>
              <br />
              特にTwitterは年齢層も広く、より気軽に広告キャンペーンを実施することができるSNSです。しかも経費も安価で済むというオマケ付き。
            </p>
            <p>
              今回はTwitter広告運用の２大メインストリームである
              <strong>「フォロー＆リツイートキャンペーン」</strong>と
              <strong>「ハッシュタグキャンペーン」。</strong>
              それぞれのメリットとデメリットに迫ります。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    フォロー＆リツイートキャンペーンの特徴{" "}
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    ハッシュタグキャンペーンの特徴
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">フォロー＆リツイートキャンペーンの特徴</h2>
              <p>
                最も手軽で、キャンペーン参加者にとって参加しやすいのがこの
                <strong>「フォロー＆リツイートキャンペーン」。</strong>
                キャンペーン参加者は特定のアカウントをフォローし、ツイートをリツイートします。
              </p>
              <p>
                ご存知のとおり、リツイートはユーザーにとっても比較的ハードルが低く受け入れられやすいもので、ブランド認知が低い企業やサービスにも最適です。「あまり知らない会社（サービス）だけど面白そう」という心理を生かすにはリツイートは非常に有効な手段です。
              </p>
              <p>
                リツイートに比べてアカウントのフォローは、参加者にとって比較的ハードルが高い行動になります。しかし、自社の非売品ノベルティやAmazonギフトカードなどの目を引く景品を用意することでフォローに対する精神的な障壁を下げることもできるので工夫をしてみましょう。
              </p>
              <p>
                肝心な広告効果としてはリツイートをするという条件もあるので一時的なフォローのみと異なり拡散性が非常に高く、多くのユーザーの目に留まるキャンペーンといえるでしょう。
              </p>
              <p>
                一方のデメリットはフォロワーの増加こそ見込めるもののリツイートだけで製品やサービスへの深い理解を得るのは難しいというものです。
              </p>
              <h2 id="id2">ハッシュタグキャンペーンの特徴</h2>
              <p>
                もう一手は<strong>ハッシュタグを使ったキャンペーン。</strong>
                アカウントをフォローし、特定のハッシュタグをつけて投稿するパターンです。
              </p>
              <p>
                例えば「#ABC製品との思い出」というハッシュタグを作りABC社の製品を使っている写真を募集する企画などが考えられます。
              </p>
              <p>
                このキャンペーンの大きなメリットは消費者が自社製品やサービスを使っている「リアル」を伝えることができることです。「宣伝臭さ」が大きく減り、より効果的な広告訴求ができると考えられます。しかし逆に考えると多くのユーザーに対して企業の認知度がありサービスがすでに使用されていることが前提です。
              </p>
              <p>
                スタートアップ企業であったり、クラウドファンディングであったり「初めまして」のユーザーが多いケースではあまり相性がよくありません。すでにある程度ブランド認知がされているメーカーやサービスに最適なプランとも言えますね。
              </p>
              <h2 id="id3">まとめ</h2>
              <p>
                一長一短がある「フォロー＆リツイートキャンペーン」と「ハッシュタグキャンペーン」ですが、
                <strong>
                  前者はスタート間もないサービスや認知度の低い企業に、後者はすでに認知度があるサービスや企業に向いています。
                </strong>
              </p>
              <p>
                特に流動的で人も話題も流れが急激なTwitterにおいては、企業の成長とともに柔軟に広告戦略を変えていくことが重要です。
              </p>
            </div>
            <Link to={`/`}>
              <div className="blog__text-center">
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="PARKLoT(パークロット)"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/brand/blue.png"
                  maxwidth={300}
                  className="m-auto"
                />
              </div>
            </Link>
            <p>
              <a
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
              </a>
            </p>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="matsuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default CustomerAppealSns
